import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useTransition, animated, config } from 'react-spring'
import { GlobalContext } from '../context/global'

const slides = [
  { id: 4, url: '/img/03122023/esimca-03122023-1.jpg' },
  { id: 5, url: '/img/03122023/esimca-03122023-2.jpg' },
  { id: 6, url: '/img/03122023/esimca-03122023-3.jpg' },
  { id: 7, url: '/img/03122023/esimca-03122023-4.jpg' },
  { id: 8, url: '/img/03122023/esimca-03122023-5.jpg' },
  { id: 9, url: '/img/03122023/esimca-03122023-6.jpg' },
  { id: 10, url: '/img/03122023/esimca-03122023-7.jpg' },
  { id: 11, url: '/img/03122023/esimca-03122023-8.jpg' },
  { id: 12, url: '/img/03122023/esimca-03122023-9.jpg' },
  { id: 13, url: '/img/03122023/esimca-03122023-10.jpg' },
  { id: 14, url: '/img/03122023/esimca-03122023-11.jpg' },
  { id: 0, url: '/img/sample-hero-image-1.jpg' },
  { id: 1, url: '/img/sample-hero-image-2.jpg' },
  { id: 2, url: '/img/sample-hero-image-3.jpg' },
  { id: 3, url: '/img/sample-hero-image-4.jpg' },
]

const HeroContainer = styled.section`
  height: auto;
  display: block;
  width: 100%;
  padding-top: 54px;
  box-sizing: border-box;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: inline-flex;
    align-content: stretch;
    padding: unset;
  }
`

const Content = styled.div`
  color: ${props => props.theme.palette.primary.contrast};
  width: auto;
  background-color: ${props => props.theme.palette.primary.main};
  flex-grow: 1;
  z-index: 1;
  padding-top: ${props => props.theme.spacing * 4}px;
  padding-left: ${props => props.theme.spacing * 4}px;
  padding-right: ${props => props.theme.spacing * 4}px;
  padding-bottom: ${props => props.theme.spacing * 4}px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/sample-hero-image-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: block;
    text-align: left;
    height: 400px;
    position: relative;
    padding-top: ${props => props.theme.spacing * 4}px;
    padding-bottom: ${props => props.theme.spacing * 4}px;
    padding-left: ${props => props.theme.spacing * 8}px;
    padding-right: ${props => props.theme.spacing * 8}px;
    width: 38.19530284301607vw;

    background: unset;
    background-size: unset;
    background-repeat: unset;
  }
`

const Header = styled.h2`
  margin: 0;
  font-size: 2rem;
  color: ${props => props.theme.palette.primary.main};

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 3.5rem;
    color: ${props => props.theme.palette.primary.contrast};
  }
`

const Slogan = styled.p`
  font-size: 1.5rem;
  font-weight: ${props => props.theme.typography.variant.caption.fontWeight};
  margin-bottom: ${props => props.theme.spacing * 4}px;
  color: ${props => props.theme.palette.primary.main};

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 1.25rem;
    color: ${props => props.theme.palette.primary.contrast};
  }
`

const HeroButton = styled.button`
  position: relative;
  font-size: 1.125rem;
  background-color: transparent;
  border: 3px solid ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.primary.main};
  padding: ${props => props.theme.spacing * 2}px;
  transition: all 0.6s;
  cursor: pointer;

  :after {
    transition: right 0.3s;
    content: "";
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 100%;
    height: 2px;
    background-color: ${props => props.theme.palette.primary.main};
  }

  :hover {
    background-color: ${props => props.theme.palette.primary.contrast};
    border: 1px solid ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};
    &:after {
      right: 16px;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 1.5rem;
    color: inherit;
    background-color: transparent;
    border: 1px solid ${props => props.theme.palette.primary.contrast};
  }
`

const HeroImageContainer = styled.div`
  flex-grow: 2;
  width: 61.80469715698393vw;
  position: relative;
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: block;
  }
`

const HeroImage = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Hero = () => {
  const [imageIndex, setImageIndex] = useState(0)
  const [state, dispatch] = useContext(GlobalContext)
  const transitions = useTransition(slides[imageIndex], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses
  })
  useEffect(() => void setInterval(() => setImageIndex(state => (state + 1) % 14), 5000), [])

  return (
    <HeroContainer>
      <Content>
        <Header>Build the future Today</Header>
        <Slogan>Share us your idea and we will build it for you.</Slogan>
        <HeroButton>
          Contact us
        </HeroButton>
      </Content>
      <HeroImageContainer>
        {
          transitions.map(({ item, props, key}) => (
            <HeroImage
              key={key}
              style={{ 
                ...props,
                backgroundImage: `url(${item.url})`,
                backgroundSize: 'cover',
              }}
            />
          ))
        }
      </HeroImageContainer>
    </HeroContainer>
  )
}

export default Hero