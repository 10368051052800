import React from 'react'
import styled from 'styled-components'
import { FaHandHoldingHeart, FaAward, FaRegLightbulb, FaHandshake } from 'react-icons/fa'
import useMedia from '../hooks/useMedia'

const Container = styled.div`
  display: block;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    padding-left: ${props => props.theme.spacing * 6}px;
    padding-top: 75px;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
`
const PillarContainer = styled.div`
  display: none;
  z-index: 1;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    position: absolute;
    top: -100px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;

    ${props => {
      if (props.right) {
        return `right: 0px;`;
      } else {
        return `left: 0px;`;
      }
    }}
  }
`

const Pillar = styled.div`
  padding-top: 150px;
  text-align: center;
  height: 500px;
  width: 300px;
  background-color: ${props => props.theme.palette.primary.contrast};
  margin-left: ${props => props.theme.spacing * 2}px;
  margin-right: ${props => props.theme.spacing * 2}px;
`

const ImageContainer = styled.div`
  z-index: 2;
  width: 50%;
  height: auto;
  margin: auto;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin: unset;
    margin-right: ${props => props.theme.spacing * 8}px;
    width: 400px;
    height: 400px;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: 400px;
    height: auto;
    width: initial;
  }
`

const ContentContainer = styled.div`
  padding: ${props => props.theme.spacing * 2}px;
  text-align: center;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    text-align: left;
    padding: ${props => props.theme.spacing * 4}px;
  }
`

const Content = styled.p`
  font-size: 1.25rem;
  color: ${props => props.theme.palette.primary.disabled};
`

const Header = styled.h3`
  color: ${props => props.theme.palette.primary.contrast};
  font-size: 2rem;
  font-weight: 700;
  margin-top: 0;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: inherit;
  }
`

const CategorySection = styled.div`
  margin-left: ${props => props.theme.spacing * 2}px;
  margin-right: ${props => props.theme.spacing * 2}px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.palette.primary.contrast};

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    padding-top: 75px;
    background-color: ${props => props.theme.palette.primary.contrast};
    min-height: 400px;
    color: ${props => props.theme.palette.primary.main};
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: auto;
    box-sizing: border-box;
  }
`

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0px;
  padding: ${props => props.theme.spacing * 2}px;
`

const CategoryIconContainer = styled.div`
  font-size: 2rem;
`
const CategoryHeaderContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing * 2}px;
`
const CategoryHeader = styled.h5`
  font-size: 1.25rem;
  margin: 0;
`
const CategoryContentContainer = styled.div`
  text-align: center;
  color: ${props => props.theme.palette.primary.disabled};
`

const Category = ({
  icon,
  header,
  children,
}) => {
  return (
    <CategoryContainer>
      <CategoryIconContainer>{ icon }</CategoryIconContainer>
      <CategoryHeaderContainer>
        <CategoryHeader>
          { header }
        </CategoryHeader>
      </CategoryHeaderContainer>
      <CategoryContentContainer>{ children }</CategoryContentContainer>
    </CategoryContainer>
  )
}

const InfoSection = ({
  imageLink,
  header,
  children,
  variant = 'left',
}) => {
  const device = useMedia()

  return (
    <Container>
      <PillarContainer right={variant === 'right'}>
        <Pillar />
      </PillarContainer>
      {
        (Boolean(device) && (device === 'Mobile-XS' || device === 'Mobile' || device === 'Tablet')) &&
        <>
          <ImageContainer>
              <Image src={imageLink} />
          </ImageContainer>
          <ContentContainer>
            <Header>{ header }</Header>
            <Content>
              { children }
            </Content>
          </ContentContainer>
        </>
      }
      {
        ((Boolean(device) && (device === 'Desktop' || device === 'Desktop-XL' || device === 'Desktop-XXL')) && variant === 'left') &&
        <>
          <ImageContainer>
            <Image src={imageLink} />
          </ImageContainer>
          <ContentContainer>
            <Header>{ header }</Header>
            <Content>
              { children }
            </Content>
          </ContentContainer>
        </>
      }
      {
        ((Boolean(device) && (device === 'Desktop' || device === 'Desktop-XL' || device === 'Desktop-XXL')) && variant === 'right') &&
        <>
          <ContentContainer>
            <Header>{ header }</Header>
            <Content>
              { children }
            </Content>
          </ContentContainer>
          <ImageContainer>
            <Image src={imageLink} />
          </ImageContainer>
        </>
      }
    </Container>
  )
}

const AboutUs = (props) => {
  return (
    <div {...props}>
      <InfoSection 
        imageLink="/img/vision.jpg"
        header="Our Vision"
      >
          To be known as the most passionate and trusted Engineering Construction Development company that is beyond compare in the industry
          when it comes to its services and also shares its values with its clients towards the achievement of every finished project.
      </InfoSection>
      <InfoSection 
        imageLink="/img/mission.jpg"
        header="Our Mission"
        variant="right"
      >
          To be the greatest conglomerate in Engineering Construction Development Field such as Design, Build, Project Management, Consultancy and Trading
          in the Philippines and also to compete internationally.
      </InfoSection>
      <InfoSection 
        imageLink="/img/values.jpg"
        header="Our Values"
      >
        We uphold on these four core values: <b>Integrity</b>, <b>Excellence</b>, <b>Innovative</b> and <b>Cooperative Spirit</b>.
      </InfoSection>
      <CategorySection>
        <Category
          icon={<FaHandHoldingHeart />}
          header="Integrity"
        >
          We have a firm compassion about building solid relationships with our clients, partners, communities and fellow employees.
          Encourage truthfulness and accuracy in all matters. Build trust by having time to listen and a word of honor.
        </Category>
        <Category
          icon={<FaAward />}
          header="Excellence"
        >
          Seriously committed in delivering client's success thru achievement of their dreams. Always dedicated to exceed 
          the customer's satisfaction on our services.
        </Category>
        <Category
          icon={<FaRegLightbulb />}
          header="Innovative"
        >
          We are open-minded for every new ideas that can produce exemplary results. Promoting environment friendly strategies upon
          building every client's goal.
        </Category>
        <Category
          icon={<FaHandshake />}
          header="Cooperative Spirit"
        >
          Building harmonious relationship to everybody towards the attainment of the focused goal.
        </Category>
      </CategorySection>
    </div>
  )
}

export default AboutUs