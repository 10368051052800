import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Container = styled.div`
  color: ${props => props.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing * 2}px;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: ${props => props.theme.spacing * 2}px;
    padding-bottom: ${props => props.theme.spacing * 2}px;
    padding-left: ${props => props.theme.spacing * 4}px;
    padding-right: ${props => props.theme.spacing * 4}px;
  }
`

const Body = styled.div`
  display: block;
`
const Header = styled.h3`
  margin-top: 0px;
  font-size: 2rem;
`

const Content = styled.p`
  font-size: 1.25rem;
  color: ${props => props.theme.palette.primary.disabled};
`

const Side = styled.div`
  display: block;
`

const Selection = styled.ul`
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    min-width: 400px;
    margin-top: 0;
    margin-left: ${props => props.theme.spacing * 8}px;
  }
`

const SelectionItem = styled.li`
  list-style: none;
  position: relative;
  border: 2px solid ${props => props.theme.palette.primary.main};
  margin-bottom: ${props => props.theme.spacing * 2}px;
  box-sizing: border-box;
  transition: all 0.3s;

  :after {
    transition: all 0.3s;
    content: "";
    position: absolute;
    right: 30px;
    top: 15px;
    display: block;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: ${props => props.theme.palette.primary.main};
  }

  :hover {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrast};
    border-color: ${props => props.theme.palette.primary.main};
    &:after {
      right: 15px;
      border-color: ${props => props.theme.palette.primary.contrast};
    }
  }
`

const SelectionItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  padding: ${props => props.theme.spacing * 2}px;
  width: 100%;
  height: 100%;
  display: block;
`

const Services = (props) => {
  return (
    <Container {...props}>
      <Body>
        <Header>Services</Header>
        <Content>
          Here are the list of services we provide. 
          We ensure that client has exceeded his expectations by providing high quality services that is aligned with industry standards. <br /><br /> 
          We cover all of your construction needs!
        </Content>
      </Body>
      <Side>
        <Selection>
          <SelectionItem>
            <SelectionItemLink to="/services/engineering-designs">Engineering Designs</SelectionItemLink>
          </SelectionItem>
          <SelectionItem>
            <SelectionItemLink to="/services/as-built-plans-preparations">As-Built Plans Preparation</SelectionItemLink>
          </SelectionItem>
          <SelectionItem>
            <SelectionItemLink to="/services/build">Build</SelectionItemLink>
          </SelectionItem>
          <SelectionItem>
            <SelectionItemLink to="/services/project-management">Project Management</SelectionItemLink>
          </SelectionItem>
          <SelectionItem>
            <SelectionItemLink to="/services/electrical-system-maintenance-and-testing">Electrical System Maintenance and Testing</SelectionItemLink>
          </SelectionItem>
          <SelectionItem>
            <SelectionItemLink to="/services/consultancy">Consultancy</SelectionItemLink>
          </SelectionItem>
          <SelectionItem>
            <SelectionItemLink to="/services/trading">Trading</SelectionItemLink>
          </SelectionItem>
        </Selection>
      </Side>
    </Container>
  )
}

export default Services