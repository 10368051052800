import React from "react"
import Layout from '../components/layout'
import Hero from '../components/hero'
import Section from '../components/section'
import AboutUs from '../blocks/AboutUs'
import Projects from '../blocks/Projects'
import ContactUs from '../blocks/ContactUs'
import Services from '../blocks/Services'
import Partners from '../blocks/Partners'
import Seo from '../components/seo';

export default function Home(props) {
  return (
    <Layout {...props}>
      <Seo title="Home" />
      <Hero />
      <Section padded>
        <AboutUs id="about" />
      </Section>
      <Section padded inverted>
        <Services id="services" />
      </Section>
      <Section padded>
        <Projects id="projects" />
      </Section>
      <Section>
        <Partners id="partners" />
      </Section>
      {/* <Section>
        <ContactUs id="contact" />
      </Section> */}
    </Layout>
  )
}
