import React from 'react'
import styled from 'styled-components'
import Gallery from 'react-grid-gallery'

const images = [
  {
    src: "/img/projects/sample-1.jpg",
    thumbnail: "/img/projects/sample-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-2.jpg",
    thumbnail: "/img/projects/sample-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-3.jpg",
    thumbnail: "/img/projects/sample-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-4.jpg",
    thumbnail: "/img/projects/sample-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-5.jpg",
    thumbnail: "/img/projects/sample-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-6.jpg",
    thumbnail: "/img/projects/sample-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 320,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-7.jpg",
    thumbnail: "/img/projects/sample-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-8.jpg",
    thumbnail: "/img/projects/sample-8.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 320,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-9.jpg",
    thumbnail: "/img/projects/sample-9.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-10.jpg",
    thumbnail: "/img/projects/sample-10.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-11.jpg",
    thumbnail: "/img/projects/sample-11.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-12.jpg",
    thumbnail: "/img/projects/sample-12.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 320,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-13.jpg",
    thumbnail: "/img/projects/sample-13.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-14.jpg",
    thumbnail: "/img/projects/sample-14.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 429,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-15.jpg",
    thumbnail: "/img/projects/sample-15.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-16.jpg",
    thumbnail: "/img/projects/sample-16.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-17.jpg",
    thumbnail: "/img/projects/sample-17.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-18.jpg",
    thumbnail: "/img/projects/sample-18.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-19.jpg",
    thumbnail: "/img/projects/sample-19.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 313,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-20.jpg",
    thumbnail: "/img/projects/sample-20.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-21.jpg",
    thumbnail: "/img/projects/sample-21.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 429,
    isSelected: false,
  },
  {
    src: "/img/projects/sample-22.jpg",
    thumbnail: "/img/projects/sample-22.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-1.jpg",
    thumbnail: "/img/projects/project-03122023-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-2.jpg",
    thumbnail: "/img/projects/project-03122023-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-3.jpg",
    thumbnail: "/img/projects/project-03122023-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-4.jpg",
    thumbnail: "/img/projects/project-03122023-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-5.jpg",
    thumbnail: "/img/projects/project-03122023-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-6.jpg",
    thumbnail: "/img/projects/project-03122023-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-7.jpg",
    thumbnail: "/img/projects/project-03122023-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-8.jpg",
    thumbnail: "/img/projects/project-03122023-8.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-9.jpg",
    thumbnail: "/img/projects/project-03122023-9.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-10.jpg",
    thumbnail: "/img/projects/project-03122023-10.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
  {
    src: "/img/projects/project-03122023-11.jpg",
    thumbnail: "/img/projects/project-03122023-11.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 426,
    isSelected: false,
  },
]

const Container = styled.div`
  padding-top: ${props => props.theme.spacing * 2}px;
  margin-left: ${props => props.theme.spacing * 4}px;
  margin-right: ${props => props.theme.spacing * 4}px;
  padding-bottom: ${props => props.theme.spacing * 2}px;
`

const Header = styled.h3`
  text-align: center;
  font-size: 2rem;
  color: ${props => props.theme.palette.primary.contrast};
  margin-bottom: ${props => props.theme.spacing * 4}px;
`

const Projects = (props) => {
  return (
    <Container {...props}>
      <Header>Projects Gallery</Header>
      <Gallery images={images} />
    </Container>
  )
}

export default Projects