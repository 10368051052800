import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin: unset;
    padding-top: ${props => props.theme.spacing * 2}px;
    margin-left: ${props => props.theme.spacing * 4}px;
    margin-right: ${props => props.theme.spacing * 4}px;
    padding-bottom: ${props => props.theme.spacing * 2}px;
  }
`

const Header = styled.h3`
  font-size: 2rem;
  color: ${props => props.theme.palette.primary.contrast};
  margin-bottom: ${props => props.theme.spacing * 4}px;
`
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
`

const Logo = styled.img`
  max-height: 120px;
`


const Partners = (props) => {
  return (
    <Container {...props}>
      <Header>Partners</Header>
      <LogoContainer>
        <Logo src="/img/partner-logo-1.jpg" />
        <Logo src="/img/partner-logo-2.jpg" />
        <Logo src="/img/partner-logo-3.jpg" />
        <Logo src="/img/partner-logo-4.jpg" />
      </LogoContainer>
    </Container>
  )
}

export default Partners